.decorative-element {
  border: 1px solid #c8c8c8;
  width: 800px;
  height: 360px;
  position: absolute;
  z-index: 2;
  pointer-events: none;
}
.decorative-element_left {
  top: 680px;
  left: 80px;
}
.decorative-element_right {
  top: 3070px;
  right: 70px;
}
@media screen and (max-width: 1023px) {
  .decorative-element {
    display: none;
  }
}
