#root {
  font-family: "Helvetica Neue";
  font-weight: 400;
  color: white;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1,
h2,
h2,
h3,
p {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
}
.content {
  max-width: 1920px;
  width: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
}
.blend {
  mix-blend-mode: exclusion;
}

.link_disabled{
  opacity: .2;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}
