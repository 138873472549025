.multimedia {
  display: flex;
  margin: auto;
  width: calc(100% - 160px);
  max-width: 1760px;
  justify-content: center;
  padding-bottom: 100px;
  border-bottom: solid 1px #131313;
}
.multimedia__content {
  display: flex;
  width: 100%;
}
.multimedia__text {
  font-size: 88px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: white;
  mix-blend-mode: exclusion;
 
}
.multimedia__text_mask {
  width: 87%;
  height: 100px;
  background-color:white;
  position: absolute;
  /* mix-blend-mode: exclusion; */
  top: 113px;
}
.multimedia__text_mask:nth-of-type(2) {
  top: 158px;
  /* background-color: teal; */
}
.multimedia__text:first-of-type {
  padding-top: 50px;
}
/* .gggg{
  overflow: hidden;
} */
.multimedia__data {
  border: 1px #131313 solid;
  height: 450px;
  width: 50%;
  padding: 50px 80px;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  overflow: hidden;
  margin: 0 0px 0 -1px;
}
.multimedia__data_l {
  height: 706px;
}
.multimedia__data-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 0;
}
.multimedia__text_translated {
  transform: translateX(32%);
  max-width: fit-content;
  margin: 0;
}
.multimedia__fill_black {
  background-color: #131313;
  opacity: 0;
  width: 25%;
  height: 0;
}
.multimedia__fill_dark-gray {
  background-color: #131313;
  opacity: 0;
  width: 25%;
  height: 0;
}
.multimedia__fill_gray {
  background-color: #131313;
  opacity: 0;
  width: 25%;
  height: 0;
}
@media screen and (max-width: 1500px) {
  .multimedia__text {
    font-size: 63px;
  }
  .multimedia__text_mask {
    height: 60px;
  }
  .multimedia__text_translated {
    transform: translateX(20%);
  }
  .multimedia__data {
    padding: 30px 60px;
  }
}
@media screen and (max-width: 900px) {
  .multimedia {
    width: calc(100% - 16px * 2);
  }
  .multimedia__text {
    font-size: 26px;
  }
  .multimedia__text_mask {
    width: 300px;
    height: 26px;
  }
  .multimedia__data {
    padding: 15px 15px;
  }
  .multimedia__text_translated {
    transform: translateX(15px);
  }
  .multimedia__data {
    height: 250px;
  }
  .multimedia__text_mask {
    width: 84%;
    height: 30px;
    background-color:white;
    position: absolute;
    top: 80px;
  }
  .multimedia__text_mask:nth-of-type(2) {
    top: 110px;
    /* background-color: teal; */
  }
}

