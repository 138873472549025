.card-img {
  width: 100%;
  aspect-ratio: 16 / 9;
  max-height: 675px;
  min-height: 0;
  object-fit: cover;
  /* position: relative; */
  /* top: 0; */
  z-index: 0;
  transition: all 1s ease 0s;
}

.card__item:nth-of-type(odd) {
  align-self: flex-end;
}
.card__item {
  cursor: pointer;
}
.projects__card {
  width: 45%;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px;
}
.projects__card:nth-of-type(3n + 4) {
  width: 62.5%;
}
.projects__card:first-of-type {
  width: 62.5%;
}
.projects__card:nth-of-type(3n) {
  margin-top: -20%;
}
.projects__card-header {
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #131313;
}
.projects__card-header_mask {
  visibility: hidden;
  width: 100%;
  height: 40px;
  background-color: white;
  position: absolute;
  bottom: 5px;
  left: 0;
  z-index: 2;
  transition: all 1s ease 0s;
}
.projects__card-header_mask_active {
  visibility: visible;
  bottom: 20px;
  /* animation: masking 0.1s linear 4s 1 normal forwards; */
}
/* @keyframes masking {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
} */
.projects__info-span_mask {
  visibility: hidden;
  width: 100%;
  height: 20px;
  background-color: white;
  position: absolute;
  bottom: -10px;
  left: 0;
  z-index: 0;
  transition: all 0.5s ease 0s;
}
.projects__info-span_mask_active {
  bottom: 0px;
  visibility: visible;
  z-index: 1;
  animation: masking 0.1s linear 4s 1 normal forwards;
}
.projects__text {
  padding-top: 40px;
  position: relative;
  overflow: hidden;
  transition: top 1s ease 0s;
}
.projects__text_active {
  top: 10px;
  position: relative;

}

.projects__text_left {
  padding-left: 80px;
}
.projects__card:nth-of-type(2n) .projects__text {
  padding-left: 80px;
}

@media screen and (max-width: 900px) {
  /* .projects__card__size_s,
  .projects__card__size_l {
    width: 96%;
    margin-bottom: 40px;
    object-fit: cover;
    padding-bottom: 0;
  } */
  .projects__card-header {
    font-size: 18px;
  }
  .projects__card,
  .projects__card:nth-of-type(3n + 4),
  .projects__card:first-of-type {
    width: calc(100% - 16px);
    max-width: 100%;
    /* margin-bottom: 0px; */
    object-fit: cover;
    padding-bottom: 40px;
  }
  .projects__card:nth-of-type(3n) {
    margin-top: 0;
  }
  .projects__card:nth-of-type(2n) .projects__text {
    padding-left: 16px;
  }
  /* .projects__card:nth-of-type(3n + 4) {
    width: 62.5%;
  }
  .projects__card:first-of-type {
    width: 62.5%;
  } */
  .projects__text {
    padding-top: 15px;
  }
}
