.button {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  border: hsl(0, 0%, 7%) 1px solid;
  background-color: white;
  color: #131313;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  /* position: absolute;
 
  bottom: 90px;
    right: 80px; */
}
.button_disabled{
  opacity: .5;
  background-color: white;
  color: #131313;
}

.button:hover {
background-color: #2882ED;
color: white;
border: rgba(40, 130, 237, 1);
cursor: pointer;
}
.button_disabled:hover{
  opacity: .5;
  background-color: white;
  color: #131313;
  cursor: default;
  border: hsl(0, 0%, 7%) 1px solid;
}
.button__container {
  position: relative;
  bottom: 13%;
  left: 40%;
  width: 400px;
  height: 400px;
  border: thistle 1px solid;
}
.button__link {
  cursor: default;
}
@media screen and (max-width: 1023px) {
  .button {
    width: 157px;
    height: 157px;
    font-size: 18px;
    /* bottom: 0;
    right: 0;
    top: 0px;
    left: 50%; */
    margin-top: 30px;
  }
}
