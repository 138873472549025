.menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  max-width: 1920px;
  max-height: 100vh;
  margin: auto;
  transform: translateY(-100%);
  transition: all 0.8s ease 0s;
  overflow: hidden;
  /* opacity: 0; */
}
.menu_active {
  /* position: absolute; */
  /* top: 0;
    left: 0; */
  transform: translateY(0%);
  z-index: 4;
  overflow: visible;
}
.menu__internal {
  background-color: #131313;
  position: relative;
  /* color: white; */
  display: flex;
  flex-direction: column;
  padding: 155px 80px 30px;
  width: 66%;
  overflow: hidden;
  box-shadow: 34.1756px 89.7108px 80px rgba(0, 0, 0, 0.17);
}
.menu__pages-link {
  cursor: pointer;
  font-size: 32px;
  letter-spacing: -0.02em;
  color: white;
}
.menu__pages-link_active {
  opacity: 0.5;
  text-decoration: none;
  cursor: default;
}
.menu__pages {
  padding: 0 0 25.8% 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: 256px;
  overflow: hidden;
}
.menu__connect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: 90px;
  padding-bottom: 50px;
}
.menu__connect-link {
  color: white;
  font-size: 18px;
  line-height: 0.9;
  letter-spacing: -0.02em;
}
.menu__footer {
  display: flex;
  /* justify-content: space-between; */
}
.menu__footer-info {
  color: white;
  opacity: 0.5;
  margin-right: 105px;
}

.menu__footer-lang-btn {
  background-color: transparent;
  padding: 0;
  border: none;
  color: white;
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
}
.menu_social {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 155px 79px 57px;
  align-items: flex-start;
  width: 34%;
  height: fit-content;
  position: relative;
  /* filter: drop-shadow(34.1756px 89.7108px 80px rgba(0, 0, 0, 0.17))
    drop-shadow(22.1509px 58.1459px 46.8519px rgba(0, 0, 0, 0.129074)); */
  box-shadow: 22.1509px 58.1459px 46.8519px rgba(0, 0, 0, 0.129074);
}
.menu__social-link {
  color: #131313;
  padding-bottom: 15px;
}
.menu__counter {
  font-size: 12px;
  line-height: 0.9;
  letter-spacing: -0.02em;
  vertical-align: super;
  padding-left: 5px;
  position: absolute;
}
.menu__pages-masks {
  position: absolute;
  width: 100%;
  left: 20%;
  top: 147px;
  height: 262px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
}
.menu__pages-mask {
  width: 100%;
  height: 30px;
  /* background-color:thistle; */
  background-color: #131313;
  /* transform: translateY(-30px); */
}
.menu__footer-mask {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 120px;
  /* background-color:thistle; */
  background-color: #131313;
}
.menu__social-mask {
  /* position: absolute; */
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  /* background-color:thistle; */
  background-color: white;
}
@media screen and (max-width: 1024px) {
  .menu {
    flex-direction: column;
    max-height: 100vh;
  }
  .menu__internal {
    padding: 95px 16px 30px;
    width: 100%;
    box-sizing: border-box;
    min-height: 530px;
    height: fit-content;
  }
  .menu__pages-link {
    font-size: 26px;
  }
  .menu__pages {
    padding: 0 61px 70px;
    height: 195px;
  }
  .menu__connect {
    height: 86px;
    padding-bottom: 30px;
  }
  .menu__pages-masks {
    left: 61px;
    top: 94px;
    height: 195px;
    width: 100%;
  }
  .menu__footer {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .menu__footer-info {
    font-size: 10px;
    padding: 0;
  }
  .menu__footer p {
    width: 100%;
  }
  /* .menu__footer a {
    order: 3;
} */
  .menu__footer-lang-btn {
    font-size: 10px;
  }
  .menu_social {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 30px 16px 54px;
  
    width: 34%;
   
  }
  .menu__footer-mask {
    bottom: 304px;
    width: 100%;
    height: 90px;
  }
  .menu__social-mask {
    width: 70%;
    height: 30px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1500px) {
  .menu__footer-info {
    color: white;
    opacity: 0.5;
    margin-right: 20%;
  }
}