.project {
  padding-top: 220px;
  /* padding-bottom: 200px; */
  position: relative;
  z-index: 3;
  width: 100%;
}
.project__info {
  padding-top: 360px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  z-index: 3;
}
.project__tags {
  width: 33%;
  background-color: #131313;
  margin-top: 15%;
  padding: 50px 80px;
  box-sizing: border-box;
  height: 810px;
}
.project_tag-list {
  color: white;
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.04em;
  list-style-type: none;
  padding: 50px 20%;
}
.project_tag-item {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.project_tag-item::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: white;
  margin-right: 19px;
  transform: translateY(-110%) rotate(45deg);
}
.project__cover {
  width: 67%;
  height: fit-content;
  max-height: 720px;
  object-fit: cover;
}
.project__description {
  width: 59%;
  color: #131313;
  /* padding-bottom: 150px; */
}
.project__title-container {
  padding: 150px 0;
  width: 44%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.project__about-title {
  font-size: 88px;
  line-height: 1;
  letter-spacing: -0.04em;
  color: #131313;
}
.project__about-title_left {
  margin-right: auto;
}
.project__about-title_right {
  margin-left: auto;
}
.project__lead {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  width: 63%;
  margin-left: auto;
  padding-bottom: 100px;
}
.project__text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 44px;
  padding-bottom: 88px;
}
.project__text_left {
  width: 35%;
  max-width: 400px;
}
.project__text_right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 44px;
}
.project__text_right {
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  text-indent: 80px;
}
/* .project__text_right::before{
  content: "do eiusmo";
  width: 30px;
  color: transparent;
} */

.project__about-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.project__comaker-logo {
  height: 50px;
  padding-left: 16%;
}
.project__media-list {
  width: calc(100% - 160px);
  display: flex;
  flex-direction: column;
  max-width: 1760px;
  position: relative;
  z-index: 2;
}
.project__media-item {
  padding: 0px 0 100px 0;
  display: flex;
  position: relative;
  z-index: 2;
  justify-content: space-between;
}
.project__media-item_column {
  flex-direction: column;
}
.project__media-video {
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 —  75%) */
  height: 0;
  overflow: hidden;
  margin: 150px 0;
}
.project__media-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}
.project__media-videofile {
  position: relative;
  z-index: 2;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
video {
  width: 100%;
  height: auto;
  max-height: 100%;
}
/* .media_position_left {
  justify-content: space-between;
}
.media_position_right {
  justify-content: space-between;
} */
.media_size_large {
  width: 100%;
}
.media_size_medium {
  width: 72%;
  object-fit: contain;
}
.project__img {
  width: 100%;
}
.media__text {
  max-width: 300px;
  color: #131313;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
}
.project__text-span {
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: rgba(13, 13, 13, 0.5);
  padding-bottom: 25px;
}
.project__media-container {
  max-width: 100%;
  /* transform: translateX(-100%); */
}
.project__text-container_top {
  padding: 150px 0 100px;
  position: relative;
  left: 50%;
  max-width: 400px;
}
.project__text-container_left {
  padding-top: 100px;
}
.project__text-container_right {
  padding: 100px 0 0 80px;
  order: 2;
}
.project__title-container__bottom {
  margin: 0 auto 0 16.3%;
  padding-bottom: 150px;
  width: 372px;
  display: flex;
  flex-direction: column;
}
.project__card {
  padding: 0;
  max-width: 45%;
}
.project__card:last-of-type {
  margin-top: -17%;
}
@media screen and (min-width: 701px) and (max-width: 1024px) {
  .project {
    padding: 120px 0 0 0;
    max-width: 100%;
  }
  .project__info {
    padding: 145px 0 0 0;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .project__description {
    width: calc(100% - 32px);
    padding-bottom: 70px;
  }
  .project__tags {
    width: 73%;
    background-color: #131313;
    margin-top: 0;
    padding: 20px 15px;
    box-sizing: border-box;
    height: 300px;
  }
  .project_tag-list {
    font-size: 18px;
    padding: 20px 30px;
    max-width: fit-content;
  }
  .project_tag-item::before {
    content: "";
    position: relative;
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: white;
    margin-right: 10px;
    transform: translateY(-35%) rotate(45deg);
  }
  .project_tag-item {
    padding-bottom: 10px;
  }
  .project__cover {
    width: 100%;
  }
  .project__title-container {
    width: 105px;
    padding: 70px 0;
  }
  .project__about-title {
    font-size: 62px;
  }
  .project__about-title_left {
    padding-bottom: 6px;
  }
  .project__lead {
    font-size: 18px;
    width: 50%;
    padding-bottom: 50px;
    margin-left: 50%;
  }
  .project__text-container {
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 14px;
    align-items: flex-end;
    row-gap: 0px;
    padding-bottom: 0px;
  }
  .project__text_left {
    width: 50%;
    max-width: 50%;
    padding-bottom: 50px;
  }
  .project__text_right-container {
    width: 100%;
    row-gap: 35px;
  }
  .project__text_right {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    text-indent: 34px;
  }
}
@media screen and (max-width: 700px) {
  .project {
    padding: 120px 0 0 0;
    max-width: 100%;
  }
  .project__info {
    padding: 145px 0 0 0;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  .project__description {
    width: calc(100% - 32px);
    padding-bottom: 0px;
  }
  .project__title-container {
    width: 105px;
    padding: 70px 0;
  }
  .project__about-title {
    font-size: 26px;
  }
  .project__about-title_left {
    padding-bottom: 6px;
  }
  .project__lead {
    font-size: 18px;
    width: 234px;

    padding-bottom: 50px;
  }
  /* .project__text-container {
    flex-direction: column;
    justify-content: space-between;
    row-gap: 0px;
    padding-bottom: 70px;
  } */
  .project__tags {
    width: 73%;
    background-color: #131313;
    margin-top: 0;
    padding: 20px 15px;
    box-sizing: border-box;
    height: 300px;
  }
  .project_tag-list {
    font-size: 18px;
    padding: 20px 30px;
    max-width: fit-content;
  }
  .project_tag-item::before {
    content: "";
    position: relative;
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: white;
    margin-right: 10px;
    transform: translateY(-35%) rotate(45deg);
  }
  .project_tag-item {
    padding-bottom: 10px;
  }
  .project__cover {
    width: 100%;
  }
  .project__about-footer {
    width: 70%;
  }
  .project__text-container {
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 14px;
    align-items: flex-end;
    row-gap: 0px;
    padding-bottom: 0px;
  }
  .project__text_left {
    width: 234px;
    padding-bottom: 50px;
  }
  .project__text_right-container {
    width: 100%;
    row-gap: 35px;
  }
  .project__text_right {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
    text-indent: 34px;
  }
  .project__text-container_top {
    padding: 15px 0 0px;
    position: relative;
    left: 0%;
    max-width: fit-content;
    order: 2;
  }
  .project__text-container_left {
    padding: 15px 0;
  }
  .project__text-container_right {
    padding: 15px 0;
    order: 2;
  }
  .project__media-list {
    width: 100%;
    align-items: center;
  }
  .project__media-item {
    padding: 0px 0 40px 0;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    width: calc(100% - 32px);
  }
  /* .project__media-item:last-of-type {
    padding: 0;
  } */
  .project__media-video {
    width: 100%;
    padding-bottom: 56.25%;
    margin: 70px 0;
  }
  .project__media-videofile {
    width: 100%;
    padding-top: 50px;
  }
  .media__text {
    max-width: 100%;
    font-size: 14px;
    /* padding-top: 10px; */
  }
  .project__text-span {
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: rgba(13, 13, 13, 0.5);
  }
  .media_size_medium {
    width: 93%;
  }
  .video-btn {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .project__title-container__bottom {
    margin: 0 auto 0 20%;
    width: 105px;
    padding: 70px 0;
  }
  .project__card {
    padding: 0;
    max-width: 100%;
  }
  .project__card:last-of-type {
    margin-top: 0;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1100px) {
  .project_tag-list {
    font-size: 32px;
    padding: 20px 0px;
  }
  .project__tags {
    margin-top: 150px;
    padding: 50px 50px;
    height: 510px;
  }
}
/* .cursor{
  background-image: url(../images/Play-btn1.svg);
  width: 160px;
  height: 160px;
  position: absolute;
  top: 0;
  left: 0;
} */
