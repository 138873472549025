.office {
  width: 100%;
  display: flex;
  padding: 150px 0 0;
}
.office__text {
  background-color: #131313;
  margin-top: 250px;
  flex-basis: 640px;
  padding: 50px 80px;
  height: 810px;
}
.office__invite {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: white;
  padding: 50px 0 0 10%;
  max-width: 248px;
}
.office__hours {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: white;
  padding: 40px 0 0 10%;
  max-width: 248px;
}
.office__photo {
  width: 66%;
  height: 66%;
}
@media screen and (max-width: 900px) {
    .office {
        flex-direction: column-reverse;
        padding: 70px 0 0;
      }
      .office__photo {
        width: 100%;
      
      }
      .office__text {
        background-color: #131313;
        margin-top: 0;
        flex-basis: 269px;
        padding: 20px 14px;
        width: 58.4%;
        height: 270px;
      }
      .office__invite {
        display: none;
      }
      .office__hours {
        font-size: 18px;
        padding: 96px 0 0 10%;
        max-width: 94px;
      }
}
@media screen and (min-width: 901px) and (max-width: 1100px) {
  .office__text {
    height: auto;
  }
}

