.contacts {

  width: calc(100% - 160px);
  margin: auto;
  max-width: 1760px;
}
.contacts-home {
  width: calc(100% - 160px);
  max-width: 1760px;
  border-top: 1px #131313 solid;
}
.contacts__card {
  padding: 50px 80px;
  display: flex;
  flex-direction: column;
  width: 50%;
  max-height: 450px;
  border: 1px #131313 solid;
  box-sizing: border-box;
  margin: -1px 1px 0 -1px;
}
.contacts__cards {
  max-width: 1760px;
  display: flex;
  flex-wrap: wrap;
}
.contacts__card_black {
  background-color: #131313;
  max-height: 810px;
  min-height: 371px;
}
.contacts__data {
  font-size: 88px;
  letter-spacing: -0.04em;
  text-decoration: none;
  padding-top: 50px;
  color: white;
  mix-blend-mode: exclusion;
  display: flex;
  flex-wrap: wrap;
  max-width: 517px;
}
.contacts__phone_left {
  padding-right: 20%;
}
.contacts__phone_center {
  margin-right: auto;
}
.contacts__phone_center:last-of-type {
  padding-left: 30%;
  padding-bottom: 20px;
  margin: 0;
}
.contacts__email_center {
  transform: translateY(-22px);
  padding-left: 145px;
}

.contacts__card_phone {
  margin-top: 23%;
  background-color: white;
}
.contacts__address-text {
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  max-width: 408px;
  margin: auto;
  padding-top: 50px;
  mix-blend-mode: exclusion;
}
/* .contacts__address {
  display: flex;
  padding: 50px 0 0 200px;
} */
.contacts__address-map {
  align-self: flex-end;
  cursor: pointer;
  transition: all  cubic-bezier(.04,1.19,1,1.69) .5s ;
}
.contacts__address-map:hover {
 transform: scale(1.125, 1.125);
}
/* @media screen and (max-width: 1635px) {
  .contacts__email_center {
    padding-left: 0;
  }
  
} */
@media screen and (min-width: 1101px) and (max-width: 1635px) {
  .contacts__email_center {
    padding-left: 19%;
  }
  .contacts__data {
    font-size: 63px;
  }
  .contacts__card {
    padding: 20px 40px;
  }
  .contacts__phone_center:last-of-type {
    padding-left: 27%;
  }
}
@media screen and (min-width: 903px) and (max-width: 1100px) {
  .contacts__email_center {
    padding-left: 19%;
  }
  .contacts__data {
    font-size: 50px;
  }
  .contacts__card {
    padding: 30px 30px;
  }
  .contacts__phone_center:last-of-type {
    padding-left: 72px;
  }
  .contacts__phone_left {
    padding-right: 46px;
  }
}
@media screen and (max-width: 903px) {
  .contacts {
    padding-top: 22px;
    width: calc(100% - 15px * 2);
  }
  .contacts-home {
    padding-top: 22px;
    width: calc(100% - 15px * 2);
  }
  .contacts__data {
    font-size: 26px;
    max-width: 164px;
    padding-top: 20px;
  }
  .contacts__card {
    padding: 20px 15px;
    max-height: fit-content;
    max-width: 100%;
  }
  .contacts__cards {
    max-width: 100%;
  }
  .contacts__card_phone {
    margin-top: auto;
  }
  .contacts__email_center {
    transform: translateY(0px);
    padding-left: 0px;
  }
  .contacts__phone_left {
    padding-right: 10px;
  }
  .contacts__phone_center:last-of-type {
    padding-left: 24px;
    padding-bottom: 20px;
  }
  .contacts__address-text {
    font-size: 18px;
    max-width: 230px;
    margin: 0;
    align-self: center;
  }
  .contacts__card_address {
    width: 100%;
  }
  .contacts__address-map {
    width: 63px;
    padding-top: 20px;
  }
}

