.cursor {
  transition: 0.5s cubic-bezier(0.075, -0.82, 0.3, 2.33) transform,
    opacity 0.2s cubic-bezier(0.075, -0.82, 0.3, 2.33);
  position: absolute;
  user-select: none;
  pointer-events: none;
  z-index: 2;
}

.cursor__follower {
  width: 160px;
  height: 160px;
  background-color: #f8f8f8;
  border-radius: 50%;
  transform: translate(5px, 5px);
}
.cursor__inner {
  background-image: url("../images/play.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 21px;
  height: 34px;
}
