.info-span {
  color: #898989;
  /* opacity: .5; */
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  position: relative;
  z-index: 1;
}
.info-span_place_email {
  width: 480px;
  max-width: 100%;
  align-self: flex-end;
  /* padding-top: 291px; */
  /* padding-top: 62%; */
  margin-top: auto;
  position: static;
}
.info-span_place_contacts {
  padding-bottom: 50px;
  right: 14%;
  align-self: flex-end;
}
.info-span_place_footer {
  padding-bottom: 46px;
}
.info-span_place_multimedia {
  position: relative;
  padding-bottom: 140px;
  right: 477px;
  align-self: flex-end;
}
.info-span_place_projects {

    padding-bottom: 50px;

    left: -8%;
}
.info-span_place_write-us {
  padding-top: 163px;
  position: static;
  max-width: 400px;
}
.info-span_place_write-us_popup {
  padding-top: 81px;
  position: static;
  max-width: 400px;
}
.info-span_place_services {
  padding-top: 209px;
  max-width: 400px;
}
.info-span_place_services-list {
  max-width: 64%;
  overflow: hidden;
}
.info-span_place_clients-list{
width: 40.2%;
}
@media screen and (min-width: 1101px) and (max-width: 1400px){
  .info-span_place_team-list {
    font-size: 12px;
   }
}
@media screen and (min-width: 701px) and (max-width: 1100px){
  .info-span {
    font-size: 12px;
  }
  .info-span_place_contacts {
    position: relative;
    align-self: flex-start;
    bottom: 20px;
    left: 16px;
    max-width: fit-content;
  }
  .info-span_place_projects {
    position: relative;
    left: -6%;
    max-width: fit-content;
  }
  .info-span_place_multimedia {
    top: -20px;
    left: 16px;
    align-self: flex-start;
    padding: 20px 0 0;
  }
  .info-span_place_footer {
    padding-bottom: 18px;
  }
  .info-span_place_email {
    width: auto;

    align-self: flex-start;
    /* padding-top: 136px; */
    /* bottom: 0px; */
  }
  .info-span_place_services{
    padding: 100px 0 0px;
    width: 50%;
    max-width: 300px;
    margin-right: auto;
  }
  .info-span_place_write-us {
    padding-top: 70px;
    position: static;
    max-width: 400px;
  }
  .info-span_place_team-list {
    font-size: 12px;
   }
}
@media screen and (max-width: 700px) {
  .info-span {
    font-size: 12px;
  }
  .info-span_place_write-us {
    font-size: 14px;
    padding-top: 70px;
    position: static;
    max-width: 234px;
    align-self: flex-end;
  }
  .info-span_place_write-us_popup{
    padding: 30px 0 0;
  }
  .info-span_place_services{
    padding: 50px 0 0px;
    width: 62.5%;
    margin-left: auto;
  }
  .info-span_place_services-list{
    max-width: 85.5%;
  }
  .info-span_place_projects {
    top: 14%;
    padding-bottom: 21px;
    left: 8%;
  }
  .info-span_place_multimedia {
    top: 0px;
    left: -72%;
    padding-bottom: 21px;
 
  }
  .info-span_place_team-list {
   font-size: 12px;
  }


}
