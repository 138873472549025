.error {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
}
.error__header {
  max-width: 1760px;
  width: calc(100% - 160px);
  padding-top: 45px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  mix-blend-mode: exclusion;
  align-items: center;
}

.error__menu {
  background-image: url(../images/Union.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.8s ease 0s;
}
.error__menu:focus-visible {
  outline: none;
}
.error__menu_active {
  margin-left: auto;
  transform: rotate(225deg);
}
.error__info {
  background-color: #131313;
  color: white;
  width: 61vw;
  max-width: 1280px;
  height: 75%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 70px 80px;
  box-shadow: 34.1756px 89.7108px 80px rgba(0, 0, 0, 0.17);
}
.error__text {
  font-size: 32px;
  line-height: 0.9;
}
.error__empty {
  width: 33vw;
  height: 33%;
  background-color: white;
  box-shadow: 22.1509px 58.1459px 46.8519px rgba(0, 0, 0, 0.129074);
}

@media screen and (max-width: 1023px) {
  .error__header {
    width: calc(100% - 32px);
  }
  .error__info {
    width: 50%;
    height: 53%;
    padding: 30px 16px;
  }
  .error__empty {
    width: 50%;
    height: 23%;
  }
  .error__text {
    font-size: 18px;
  }
}
