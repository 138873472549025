.promo__card {
  display: flex;
  flex-direction: column;
  position: relative;
  /* width: 50vw; */
  max-width: 50%;

}
/* .promo__card:hover {
  cursor: url('../images/Play-btn1.svg'), auto;
} */
.promo__card_translate {
  transform: translateY(218px);
}
.promo__card-img {
  /* width: 50vw; */
  max-width: 100%;
  object-fit: contain;
  padding-top: 40px;
}
.promo__card_video{
  cursor: url("../images/Play-btn.svg"), auto;
}
.promo__card_project{
  cursor: url("../images/arrow_cursor.png"), auto;
}
.promo__card-header {
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #131313;
}
.promo__card-text {
  display: flex;
  padding: 0 80px;
  justify-content: space-between;
  align-items: flex-end;
}
.promo {
  padding: 468px 0 0;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .promo {
    padding: 381px 0 0;
  }
  .promo__card_translate {
    transform: translateY(130px);
  }
  .promo__card-img {
    width: 50vw;
    aspect-ratio: 9 / 16;
    max-height: 336px;
    object-fit: cover;
    padding-top: 15px;
  }
  .promo__card-header {
    font-size: 18px;
  }
  .promo__card-text {
    flex-direction: column;
    padding: 0 16px;
    justify-content: center;
    align-items: baseline;
  }
  .promo__play-video-mobile {
    background-image: url(../images/Play-btn.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 279px;
    right: 20px;

  }
}
