.header {
  max-width: 1760px;
  width: calc(100% - 160px);
  margin: auto;
}
.header__container {
  max-width: 1760px;
  width: calc(100% - 160px);
  padding-top: 45px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 10;
  mix-blend-mode: exclusion;
  align-items: center;
}
.header__tabs {
  display: flex;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: white;
  mix-blend-mode: exclusion;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}
.header__tab {
  color: white;
}
.header__tab_active {
  opacity: 0.5;
}

.header__menu {
  background-image: url(../images/Union.svg);
  background-position: center;
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.8s ease 0s;
}
.header__menu:focus-visible {
  outline: none;
}
.header__menu_active {
  margin-left: auto;
  transform: rotate(225deg);
}
.header__counter {
  font-size: 10px;
  vertical-align: super;
  padding-left: 5px;
  mix-blend-mode: exclusion;
}
.header__tab__about-us {
  margin-left: 257px;
}
@media screen and (max-width: 1023px) {
  .header {
    width: 100%;
    padding: 0;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
    z-index: 9;
  }
  .out {
    transform: translateY(-100%);
  }
  .header__container {
    padding: 0;
    width: calc(100% - 16px * 2);
    position: relative;
    z-index: 10;
    height: 67px;
    box-sizing: border-box;
  }
  .header__menu {
    position: fixed;
    right: 16px;
    top: 20px;
    z-index: 10;
    mix-blend-mode: exclusion;
  }
  /* .header__logo{
    mix-blend-mode: exclusion;
  } */
}
