.popup {
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s linear 0s;
}
.popup_color_white {
  background-color: white;
}
.popup_color_black {
  background-color: #131313;
  cursor: url("../images/Play-btn.svg"), auto;
}
.popup_place_map {
  display: flex;
}
.popup__opened {
  visibility: visible;
  overflow: scroll;
  opacity: 1;
}
.popup__close-btn {
  background-image: url(../images/Union.svg);
  transform: rotate(45deg);
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 35px;
  right: 75px;
  background-color: transparent;
  mix-blend-mode: exclusion;
  border: none;
}
.popup__video {
  display: flex;
  margin: auto;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  cursor: url("../images/Play-btn.svg"), auto;
}
.popup__video iframe {
  width: calc(100% - 320px);
  padding-bottom: 56.5%;
  margin: auto;
  align-self: center;
  padding: 0;
  cursor: url("../images/Play-btn.svg"), auto;
}
.player {
  cursor: url("../images/Play-btn.svg"), auto;
}
.popup__map {
  width: calc(100% - 320px);
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
}
.popup__map-img {
  width: 100%;
  object-fit: contain;
}
.popup__point-aura {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: rgba(40, 130, 237, 0.3);
  display: flex;
  position: absolute;
  bottom: 23%;
  left: 43%;
}
.popup__point {
  width: 26px;
  height: 26px;
  background-color: rgba(40, 130, 237, 1);
  margin: auto;
}
@media screen and (max-width: 1024px) {
  .popup__video iframe {
    width: 100%;
    padding-bottom: 0;
    margin: auto;
    align-self: center;

  }
  .popup__close-btn {
    top: 25px;
    right: 20px;
  }
  .popup_place_map {
    align-items: flex-end;
  }
  .popup__map {
    width: auto;
    overflow: hidden;
    margin: 0;
  }
  .popup__map-img {
    height: 90vh;
    object-fit: cover;
  }
}
