.about-us {
  width: 100%;
}
.digital-first {
  width: calc(100% - 160px);
  color: #131313;
  margin: auto;
  border-bottom: #131313 1px solid;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.02em;
}

.digital-first__text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 66px;
  width: 68.2%;
  margin: auto;
}

.digital-first__lead {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  width: 63%;
  margin-left: auto;
  padding: 84px 0 100px;
}

.digital-first__text_left {
  width: 35%;
  max-width: 400px;
}
.digital-first__text_right {
  width: 50%;
  max-width: 560px;
  margin-left: auto;
}
.digital-first__text_right::before {
  content: "do eiusmo";
  width: 30px;
  color: transparent;
}
.services {
  width: calc(100% - 160px);
  color: #131313;
  display: flex;
  flex-wrap: wrap;
  border-bottom: #131313 1px solid;
}
.services:last-of-type {
  border-bottom: none;
}
.services__container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
}
.services__title-container {
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-right: 48px;
}
.services__title {
  font-size: 88px;
  line-height: 1;
  letter-spacing: -0.04em;
  color: #131313;
}
.services__title_left {
  margin-right: auto;
}
.services__title_right {
  margin-left: auto;
}
.services__list {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  transform: translateY(200px);
  margin-bottom: 300px;
}
.services__item {
  width: 50%;
  border: #131313 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  height: 400px;
  padding: 40px 80px 40px 40px;
  margin: 0px -1px -1px 0px;
}
.services__item:first-of-type {
  background-color: #131313;
  color: white;
  transform: translateY(200px);
}
.services__item:nth-of-type(2n-1) {
  transform: translateY(-200px);
}
.services__name {
  margin-bottom: auto;
  align-self: flex-start;
}

.clients__list {
  flex-direction: row-reverse;
  flex-wrap: wrap;
  transform: translateY(92px);
  margin-bottom: 192px;
}
.clients__item {
  width: 50%;
  border: 1px solid #c8c8c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 22px 0px 22px 80px;
  margin: -1px 0 0 -1px;
}
.clients__item:nth-of-type(2n-1) {
  transform: translateY(-100%);
  margin: 0px -1px;
}
.clients__name {
  font-size: 32px;
  line-height: 1;
  letter-spacing: -0.02em;
}
.team__photo {
  width: 400px;
  height: 400px;
  object-fit: cover;
}
.team__list {
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 80px;
  margin: auto;
  justify-content: flex-start;
  padding: 186px 80px 150px 160px;
}
.team__item {
  margin-top: -186px;
}
.team__item:nth-of-type(2n) {
  align-self: center;
}
.team__item:nth-of-type(3n) {
  align-self: flex-end;
}
.team__item:nth-of-type(6n) {
  align-self: center;
}
.team__item:nth-of-type(7n) {
  align-self: flex-end;
}
.team__item:nth-of-type(9n) {
  align-self: flex-start;
}
.team__name {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 40px 0 6px;
}
@media screen and (max-width: 720px) {
  .digital-first {
    width: calc(100% - 32px);
    font-size: 14px;
  }
  .digital-first__text-container {
    flex-direction: column;
    width: 100%;
    row-gap: 35px;
  }
  .digital-first__text_left {
    width: 62.4%;
    align-self: flex-end;
    padding-bottom: 15px;
  }
  .digital-first__text_right {
    width: 100%;
  }
  .digital-first__lead {
    font-size: 18px;
    width: 62.4%;
    padding: 70px 0;
  }
  .services {
    width: calc(100% - 32px);
    flex-direction: column;
  }
  .services__container {
    padding: 70px 0 0;
    width: 55%;
  }
  .services__title {
    font-size: 26px;
  }
  .services__title_right {
    padding-top: 6px;
  }
  .services__title-container {
    width: 51%;
    padding: 0;
    max-width: 92px;
  }
  .services__list {
    flex-direction: column;
    transform: translateY(0);
    margin: 70px 0;
  }
  .services__item {
    width: 100%;
    padding: 20px 15px;
    min-height: 195px;
    height: fit-content;
  
  }
  .services__item:first-of-type {
    height: 248px;
  }
  .services__item:nth-of-type(2n - 1) {
    transform: translateY(0);
  }
  .services__name {
    font-size: 18px;
  }
  .clients__list {
    flex-direction: column;
    transform: translateY(0);
    margin: 70px 0;
  }
  .clients__item {
    width: 100%;
    padding: 13px 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .clients__item:nth-of-type(2n-1) {
    transform: translateY(0);
  }
  .clients__name {
    font-size: 18px;
  }
  .team__list {
    padding: 85px 0 70px 0;
    max-width: 450px;
  }
  .team__item {
    margin-top: -85px;
  }
  .team__item:nth-of-type(2n) {
    align-self: flex-end;
    /* transform: translateY(-100px); */
    /* margin-bottom: 200px; */
  }
  .team__item:nth-of-type(3n) {
    align-self: inherit;
    /* transform: translateY(-200px); */
  }
  .team__item:nth-of-type(6n) {
    align-self: flex-end;
  }
  .team__item:nth-of-type(7n) {
    align-self: inherit;
  }
  .team__item:nth-of-type(9n) {
    align-self: inherit;
  }
  .team__name {
    font-size: 12px;
    padding: 15px 0 6px;
  }
  .team__photo {
    width: 156px;
    height: 156px;
  }
}
@media screen and (min-width: 721px) and (max-width: 1145px) {
  .digital-first__lead {
    width: 80%;
  }
  .services {
    width: calc(100% - 32px);
  }
  .services__container {
    padding: 70px 0;
  }
  .services__title {
    font-size: 63px;
  }
  .services__title_right {
    padding-top: 6px;
  }
  .services__title-container {
    width: 73%;
    padding: 0;
    max-width: 240px;
  }

  .services__item {
    width: 50%;
    padding: 40px 30px;
  }
  .services__name {
    font-size: 18px;
  }
  .clients__list {
    transform: translateY(0);
    margin: 70px 0;
  }
  .clients__item {
    margin: 0px 0px -1px 0px;
    width: 50%;
    padding: 13px 15px;
    flex-direction: column;
    align-items: flex-start;
  }
  .clients__item:nth-of-type(2n-1) {
    transform: translateY(-100%);
  }
  .clients__name {
    font-size: 18px;
  }
  .team__list {
    padding: 30px 100px 70px;
    /* max-width: 450px; */
  }
  .team__item {
    margin-top: -85px;
  }
  .team__name {
    font-size: 12px;
    padding: 15px 0 6px;
  }
  .team__photo {
    width: 156px;
    height: 156px;
  }
}
@media screen and (min-width: 1146px) and (max-width: 1400px) {
  .team__item {
    margin-top: -85px;
  }
  .team__name {
    font-size: 12px;
    padding: 15px 0 6px;
  }
  .team__photo {
    width: 200px;
    height: 200px;
  }
}
@media screen and (min-width: 1401px) and (max-width: 1650px) {
  .team__item {
    margin-top: -85px;
  }
  .team__name {
    font-size: 12px;
    padding: 15px 0 6px;
  }
  .team__photo {
    width: 250px;
    height: 250px;
  }
}