.section__container {
  display: flex;
  margin: auto;
  position: relative;
}
.section__container_column {
  flex-direction: column;
  /* padding: 0 80px; */
  width: 100%;
  position: relative;
  align-items: center;
}
.section__title {
  font-size: 178px;
  line-height: 0.9;
  margin: auto;
  text-align: center;
  letter-spacing: -0.04em;
  /* width: 813px; */
  color: #131313;
  /* mix-blend-mode: exclusion; */
  /* padding-bottom: 290px; */
}
.section__title_projects {
  padding: 200px 0 150px;
}
.section__title_project{
  z-index: 0;
  position: fixed;
}
.section__link {
  font-size: 32px;
  letter-spacing: -0.02em;
  color: #131313;
  text-decoration: none;
  margin: 0 31.5% 0 auto;
  /* position: relative;
  bottom: 0;
  left: 54%; */
  width: max-content;
  align-items: center;
  /* mix-blend-mode: exclusion; */
}
.section__link_home {
  padding: 0 0 150px;
}
.section__link-img {
  padding-left: 40px;
}
.section__link-container {
  width: calc(100% - 80px * 2);
  max-width: 1760px;
  border-top: #131313 solid 1px;
  padding-top: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.section__link-container_contacts {
  border-top: none;
}
.section__title-mask {
  background-color: white;
  margin: auto;
  position: absolute;
  bottom: -20px;
  left: 0px;
  width: 100%;
  height: 180px;
}
.section__title-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.section__title-mask_project {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 180px;
  top: 0px;
  /* border: thistle solid 1px; */
}
.section__title-container_projects-home, .section__title-container_contacts-home{
  padding: 280px 0 208px;
  position: relative;
  width: 100%;
  overflow: hidden;
  /* margin-bottom: 208px; */
}
.section__title-container_multimedia {
  cursor: url("../images/Moji-cursor.png"), auto;
  padding: 200px 0 128px;
}
.section__title_digital-first, .section__title_contacts{
  padding: 200px 0 150px;
}

@media screen and (max-width: 700px) {
  /* .section__title-container {
    padding-top: 150px;
    padding-bottom: 100px;
  } */
  .section__title {
    font-size: 48px;
  }
  .section__title_digital-first, .section__title_contacts{
    padding: 120px 0;
  }
  .section__title-container_projects-home, .section__title-container_contacts-home{
    padding: 120px 0;
  }
  .section__title-mask {
    top: -12px;
    height: 80px;
    /* border: thistle solid 1px; */
  }
  .section__title-mask_projects {
    top: 0px;
    height: 80px;
    /* border: thistle solid 1px; */
  }
  
  .section__link {
    position: static;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    padding: 70px 0 0;
    align-self: flex-end;
    margin: 0;
  }
  .section__link_home {
    padding: 70px 15px 70px 0;
  }
  /* .section__link_projects{
    margin: 0;
  } */
  .section__link-img {
    padding-left: 20px;
    width: 26px;
  }
  .section__title_projects {
    padding: 120px 0 120px;
  }
  .section__link-container {
    width: calc(100% - 16px * 2);
    padding-top: 0px;
    margin: 70px auto 0;
  }
  .section__link-container_contacts {
    margin: 0px auto 0;
  }
}

@media screen and (min-width: 701px) and (max-width: 1100px) {
  .section__title {
    font-size: 87px;
  }
  .section__title-mask {
    top: -12px;
    height: 80px;
    /* border: thistle solid 1px; */
  }
  .section__title-mask-projects {
    /* top: -333px; */
    bottom: 0;
    height: 98px;
    /* border: thistle solid 1px; */
  }
  .section__link {
    position: static;
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    padding: 70px 0 0;
    align-self: flex-end;
  }
  .section__link_home {
    padding: 0px 15px 70px 0;
  }
  .section__link-img {
    padding-left: 20px;
    width: 26px;
  }
  .section__link-container {
    width: calc(100% - 16px * 2);
    padding-top: 0px;
    /* margin: 0px auto 0; */
  }
}
