.footer {
  /* background-color: white; */
  /* mix-blend-mode: exclusion; */
  max-width: 1760px;
  width: calc(100% - 160px);
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 150px 0 100px;
}
.footer__nav {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.footer__social-media {
  display: flex;
  flex-direction: column;
}
.footer__link {
  color: #131313;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.02em;
  padding-bottom: 16px;
}
.footer__link_disabled {
  opacity: 0.3;
}
.footer__info {
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  mix-blend-mode: exclusion;
  display: flex;
  align-items: flex-start;
  padding-bottom: 30px;
}
.footer__logo {
  padding: 2px 5px 0 0;
  mix-blend-mode: exclusion;
}
.footer__moji {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__moji-img {
  padding-bottom: 20px;
}
@media screen and (max-width: 1023px) {
  .footer {
    width: calc(100% - 15px * 2);
    padding: 70px 0 50px;
    flex-direction: column;
  }
  .footer__link {
    font-size: 12px;
    padding-bottom: 8px;
  }
  .footer__nav {
    width: 100%;
  }
  .footer__moji-img {
    width: 62px;
    padding-bottom: 24px;
  }
  /* .footer__info {
    padding-bottom: 30px;
  } */
}
