.slider__btn-next {
  align-self: flex-end;
  /* transform: translateY(-400px); */
  /* padding-right: 60px; */
  position: absolute;
  top: 50%;
  right: 60px;
  cursor: pointer;
}

.slider__btn-prev {
  position: absolute;
  top: 50%;
  left: 60px;
  z-index: 2;
  cursor: pointer;
}
.slider__btn_prev {
  transform: rotate(180deg);
  /* padding-right: 60px; */
}
.slider {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1024px) {
  .slider__btn_prev {
    width: 20px;
  }
  .slider__btn_next {
    width: 20px;
  }
  .slider__btn-prev {
    left: 15px;
  }
  .slider__btn-next {
    right: 20px;
  }
}
