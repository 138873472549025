.stories {
  width: 100%;
  padding: 200px 0 150px;
}
.stories__span {
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #131313;
  max-width: 380px;
  position: absolute;
  top: 293px;
  /* left: 0; */
  right: 26%;
}
.stories__list {
  display: flex;
  flex-direction: column;
  width: 66.7%;
  margin: auto;
  column-gap: 160px;
  padding-top: 150px;
  align-items: flex-end;
}
.stories__card:first-of-type {
  align-self: flex-start;
  margin-bottom: -20%;
}
.stories__card:nth-of-type(3n + 2) {
  padding-bottom: 150px;
}
.stories__card:nth-of-type(3n + 4) {
  align-self: flex-start;
  margin-bottom: -25%;
  margin-top: -15%;
}
.stories__card {
  width: fit-content;
  min-height: 600px;
}
.stories__img {
  width: 560px;
  /* height: 100%; */
}

.stories__text {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: 86%;
}
.stories__title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}
.stories__name {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #131313;
}
.stories__date {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #131313;
}
.stories__description {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: rgba(19, 19, 19, 0.5);
  max-width: 400px;
}
@media screen and (max-width: 750px) {
  .stories {
    width: 100%;
    padding: 120px 0 0px;
  }
  .stories__list {
    width: 100%;
    column-gap: 0px;
    padding-top: 120px;
  }
  .stories__card {
    width: fit-content;
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    padding-bottom: 40px;
  }
  .stories__card:first-of-type {
    align-items: flex-end;
    margin: 0;
  }
  .stories__card:nth-of-type(3n + 2) {
    padding-bottom: 40px;
  }
  .stories__card:nth-of-type(3n + 4) {
    align-items: flex-end;
    margin: 0;
  }
  .stories__img {
    width: 314px;
  }
  .stories__text {
    padding-top: 15px;
    width: 95%;
  }
  .stories__name {
    font-size: 12px;
  }
  .stories__date {
    font-size: 12px;
  }
  .stories__description {
    font-size: 12px;
    max-width: 235px;
  }
  .stories__span {
    display: none;
  }
}
@media screen and (min-width: 751px) and (max-width: 1300px) {
  .stories__list {
    width: 100%;
  }
  .stories__card {
    width: 50%;
    min-height: 200px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
  }
  .stories__card:first-of-type {
    align-items: flex-end;
    margin: 0;
  }
  .stories__card:nth-of-type(3n + 2) {
    padding-bottom: 40px;
  }
  .stories__card:nth-of-type(3n + 4) {
    align-items: flex-end;
    margin: 0;
  }
  .stories__img {
    width: 100%;
  }
  .stories__text {
    padding-top: 15px;
    width: 95%;
  }
  .stories__name {
    font-size: 12px;
  }
  .stories__date {
    font-size: 12px;
  }
  .stories__description {
    font-size: 12px;
    max-width: 88%;
  }
  .stories__span {
    display: none;
  }
}
@media screen and (min-width: 1301px) and (max-width: 1690px) {
  .stories__list {
    width: 80%;
  }
  .stories__card {
    width: 40%;

  }
  .stories__img {
    width: 100%;
  }
}