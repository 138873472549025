.projects {
  /* padding: px 0 0px; */
  width: 100%;
  max-width: 1920px;
  margin: auto;
}
.projects__form {
  width: 100%;
}
.projects__fieldset {
  border: none;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-end;
  padding: 0 0 100px;
  width: calc(100% - 160px);
  margin: auto;
}
.projects__filter-btn {
  font-size: 18px;
  color: #131313;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-align: left;
  padding-left: 40px;
  width: 50%;
  height: 70px;
  border: #c8c8c8 solid 1px;
  background-color: transparent;
  margin: -1px -1px 0px 0px;
  transition: all ease .3s;
  box-sizing: border-box;
}
.projects__filter-btn-safari{
  margin: -1px -1px 0px 0px;
  color: #131313;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-align: left;
  padding-left: 40px;
  width: 50%;
  height: 70px;
  border: #c8c8c8 solid 1px;
  background-color: transparent;
  transition: all ease .3s;
  box-sizing: border-box;
}
.projects__filter-btn:focus, .projects__filter-btn-safari:focus  {
  background-color: #131313;
  color: white;
  border: #131313 solid 1px;
  outline: none;
}
.projects__filter-btn_focused {
  background-color: #131313;
  color: white;
  border: none;
  outline: none;
  font-size: 18px;
  line-height: 120%;
  letter-spacing: -0.04em;
  text-align: left;
  padding-left: 40px;
  width: 50%;
  height: 70px;
  border: none;
  transition: all ease .3s;
  box-sizing: border-box;
  margin: -1px -1px 0px 0px;

}
.projects__filter-btn:focus-visible, .projects__filter-btn-safari:focus-visible {
  border: #131313 solid 1px;
  outline: none;
  background-color: #131313;
  color: white;
}
.projects__filter-btn:hover, .projects__filter-btn-safari:hover {
  border: #131313 solid 1px;
  z-index: 3;
  transform: scale(.999);
}
.projects__filter-btn:first-of-type, .projects__filter-btn-safari:first-of-type {
  margin-left: calc(50% - 1px);
  position: relative;
  z-index: 2;
  outline-style: none;
}
@media screen and (max-width: 1024px) {
  .projects {
    padding: 0;
  }
  .projects__fieldset {
    padding: 0 0 40px;
    width: calc(100% - 16px * 2);
  }
  .projects__filter-btn, .projects__filter-btn-safari  {
    font-size: 12px;
    padding-left: 16px;
    height: 49px;
  }
  .projects__filter-btn:hover, .projects__filter-btn-safari:hover  {
 border: #c8c8c8 solid 1px;
  }
}
