.write-us {
  display: flex;
  width: calc(100% - 160px);
  margin: auto;
  border-top: #131313 1px solid;
  justify-content: space-between;
  margin-top: 100px;
}
.write-us__text {
  padding: 150px 0 0 9%;
  display: flex;
  flex-direction: column;
}
.write-us__text_popup {
  padding: 160px 0 0 9%;
}
.write-us__title {
  color: #131313;
  font-size: 88px;
  line-height: 1;
  letter-spacing: -0.02em;
}
.write-us__title_translated {
  transform: translateX(80px);
}
.write-us__form {
  padding: 476px 80px 0 0;
  width: 45%;
  display: flex;
  flex-direction: column;
}
.write-us__form_popup {
  padding: 300px 80px 0 0;
  width: 45%;
  display: flex;
  flex-direction: column;
}
.write-us__fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0;
}
.write-us__input {
  color: rgba(13, 13, 13, 1);
  border: none;
  border-bottom: rgba(13, 13, 13, 0.5) 1px solid;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 36px 0;
}
.write-us__input_red {
  color: rgba(237, 75, 40, 1);
}
.write-us__input::placeholder {
  color: rgba(13, 13, 13, 0.5);
}
.write-us__input:focus-visible {
  outline-style: none;
  outline: none;
  outline-color: transparent;
}
.write-us__input:focus {
  outline-style: none;
  outline: none;
  outline-color: transparent;
}
.write-us__input:nth-of-type(3) {
  padding: 36px 0 112px;
}
.write-us__checkbox-zone {
  padding-top: 62px;
  display: flex;
  justify-content: space-between;
  max-width: 437px;
}
.write-us__checkbox-label {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: rgba(13, 13, 13, 0.5);
  width: 437px;
  /* max-width: 377px;
padding-left: 30px; */
}
.write-us__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 30px;
  height: 30px;
}
.write-us__checkbox + .write-us__checkbox-label {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  user-select: none;
}
.write-us__checkbox + .write-us__checkbox-label::before {
  content: " ";
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 30px;
  border: rgba(13, 13, 13, 0.5) 1px solid;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.write-us__checkbox_red + .write-us__checkbox-label::before {
  border: rgba(237, 75, 40, 1) 1px solid;
}
.write-us__checkbox:checked + label::before {
  border: none;
  border: transparent 1px solid;
  background-image: url("../images/check.svg");
  background-size: contain;
}
.write-us__checkbox::not(:disabled):active + label::before {
  border-color: #ed4b28;
  background-image: url("../images/check.svg");
  background-size: contain;
}

.write-us__checkbox:focus-visible {
  outline: none;
}
.write-us__error {
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #ed4b28;
  transform: translateY(-31px);
}
.write-us_popup {
  margin-top: 0;
  border: none;
}

@media screen and (max-width: 700px) {
  .write-us {
    display: flex;
    width: calc(100% - 32px);
    margin: auto;
    justify-content: space-between;
    margin-top: 70px;
    flex-wrap: wrap;
  }
  .write-us__title {
    font-size: 26px;
  }
  .write-us__text {
    padding: 70px 0 0 47px;
    width: 100%;
  }
  .write-us__title_translated {
    transform: translateX(15px);
  }
  .write-us__form {
    padding: 70px 0px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .write-us__checkbox-zone {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  .write-us__input {
    font-size: 18px;
    padding: 30px 0 20px;
  }
  .write-us__input::placeholder {
    color: rgba(13, 13, 13, 1);
  }
  .write-us__input:nth-of-type(3) {
    padding: 30px 0 20px;
  }

  .write-us__checkbox-label {
    font-size: 14px;
    width: 100%;
  }
  .write-us__checkbox + .write-us__checkbox-label::before {
    margin-right: 20px;
  }
  .write-us__error {
    font-size: 10px;
    transform: translateY(-20px);
  }
  .write-us__text_popup {
    padding: 0px 0 0 48px;
  }
  .write-us__form_popup {
    padding: 0;
    width: 100%;
  }
  .write-us_popup {
    margin-top: 67px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1300px) {
  .write-us__text {
    padding: 70px 0 0 16px;
    width: 100%;
  }
  .write-us {
    flex-direction: column;
  }
  .write-us__form {
    padding: 70px 0px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .write-us__form_popup {
    padding: 70px 80px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .write-us__text_popup {
    padding: 160px 0 0 0px;
  }
}
@media screen and (min-width: 1301px) and (max-width: 1440px) {

  .write-us__text_popup {
    padding: 160px 0 0 0;
  }
}
